<template>
	<div class="container" v-title data-title="科研成果">
    <div class="breadcrumbs-box">
      <div class="breadcrumbs">
        <i class="el-icon-s-home home-icon"></i>
        <div class="item">
          <router-link tag="div" to="/">首页</router-link>
        </div>
        <i class="el-icon-arrow-right right-icon"></i>
        <div class="item">
          <div>科研成果</div>
        </div>
      </div>
    </div>
    <div class="container-box">
      <div class="other-news-list">
        <div class="title">
          科研成果
        </div>
        <ul class="news-box">
          <li :class="['news-item',typeId===item.id?'selected':'']" v-for="item in productTypeList" :key="item.id" @click="selectMenu(item)">
            <div>{{ item.typeName }}</div>
            <div>>></div>
          </li>
        </ul>
      </div>

      <div class="news-list">
        <div class="title">
          科研成果
        </div>
        <ul class="news-box" v-if="productList&&productList.length>0">
          <li class="news-item" v-for="item in productList" :key="item.id">
            <router-link target="_blank" :to="'/productdetails?id='+item.id">
              <span class="item-title">{{ item.title }}</span>
              <!-- <span class="item-time">{{ item.createTime.split(' ')[0] }}</span> -->
            </router-link>
          </li>
        </ul>
        <div class="news-box" v-else>
          <div class="no-data">暂无数据</div>
        </div>
        <div class="page-box">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="size"
            layout="prev, pager, next, total, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pageProductList,getProductTypeList } from '../../network/product'

export default {
  name: "ProductList",
  data () {
    return {
      productList: [],
      isHome: -1,
      page: 1,
      size: 10,
      total: 0,
      title: '',
      typeId: 0,
      menuList: [],
      curLayoutMode: 1,// 1列表2单页
      productTypeList: []
    };
  },
  props: {
    
  },
  components: {
    
  },
  mounted() {
    this.getProductTypeList()
    this.pageProductList(this.isHome,this.page,this.size,this.title,this.typeId)
  },
  methods: {
    selectMenu(val) {
      console.log(val)
      this.typeId = val.id
      this.pageProductList(this.isHome,this.page,this.size,this.title,this.typeId)
    },
    handleCurrentChange(val) {
      this.page = val
      this.pageProductList(this.isHome,this.page,this.size,this.title,this.typeId)
    },
    // 网络请求
    pageProductList(isHome,page,size,title,typeId) {
      pageProductList(isHome,page,size,title,typeId).then(res=>{
        if(res.status===200&&res.data) {
          this.productList = res.data
          this.total = res.total
        }
      })
    },
    getProductTypeList() {
      getProductTypeList().then(res=>{
        if(res.status===200&&res.data) {
          this.productTypeList = res.data
          this.productTypeList.unshift({
            id: 0,
            typeName: '全部'
          })
        }
      })
    },
  }
}
</script>

<style lang='less' scoped>
.container {
  width: 100%;
  .breadcrumbs-box {
    width: 100%;
    height: 40px;
    background-color: #eee;
    .breadcrumbs {
      width: 1200px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .home-icon {
        color: var(--theme-text-color);
        font-size: 14px;
      }
      .right {
        color: #535353;
        font-size: 14px;
      }
      .item {
        margin-left: 10px;
        font-size: 14px;
        color: #535353;
        cursor: pointer;
      }
    }
  }
  .container-box {
    width: 1200px;
    margin: 20px auto 35px;
    display: flex;
    justify-content: space-between;
    .news-list {
      width: 870px;
      .title {
        width: 100%;
        font-size: 18px;
        color: #313131;
        font-weight: bold;
        border-bottom: 1px solid #7d7d7d;
        padding: 10px 0;
        position: relative;
        &::after {
          content: "";
          display: inline-block;
          width: 74px;
          height: 6px;
          background-color: var(--theme-color);
          position: absolute;
          left: 0;
          bottom: -3px;
        }
      }
      .news-box {
        padding-top: 12px;
        .no-data {
          color: #999;
          font-size: 14px;
          text-align: center;
          padding: 10px 0;
        }
        .news-item {
          padding: 0 20px;
          height: 40px;
          line-height: 40px;
          border-bottom: 1px dashed #d2d2d2;
          list-style-type: none;
          padding-left: 20px;
          color: #1b1b1b;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          display: flex;

          &::before {
            content: "";
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: #1b1b1b;
            rotate: 45deg;
            margin-right: 10px;
            position: absolute;
            left: 5px;
            top: 50%;
            transform: translateY(-50%);
          }
          .item-title {
            flex: 1;
          }
          .time {
            width: 71px;
          }
        }
      }
      .page-box {
        padding-top: 60px;
        text-align: center;
      }
    }
    .other-news-list {
      width: 310px;
      .title {
        border-top: 10px solid var(--theme-color);
        background-color: #eee;
        height: 90px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 34px;
        font-size: 20px;
        font-weight: bold;
        color: var(--theme-text-color);
        .right-icon {
          color: var(--theme-text-color);
        }
      }
      .news-box {
        border-bottom: 80px solid #e5e5e5;
        .news-item {
          padding: 0 20px;
          height: 49px;
          line-height: 49px;
          list-style-type: none;
          padding-left: 20px;
          color: #1b1b1b;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #e5e5e5;
          &.selected {
            background-color: var(--theme-color);
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
